<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-text>
      <v-row no-gutters align="start">
        <v-col cols="12" md="5" class="text-center">
          <slot name="icon">
            <v-icon :color="color" size="64">{{icon}}</v-icon>
          </slot>
        </v-col>
        <v-col cols="12" md="7">
          <div class="numbers ma-0">
            <p>{{title}}</p>
            {{text}}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider
      class="mx-3"
      v-if="$slots.action"
    />

    <v-card-actions v-if="$slots.action">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: "mdi-account"
    },
    elevation: {
      type: [Number, String],
      default: 10
    },
    inline: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    offset: {
      type: [Number, String],
      default: 24
    },
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style>
.v-card--material__header .v-card {
  border-radius: 4px;
}

.v-card .numbers {
  font-size: 2em;
  text-align: right;
  font-weight: 500;
}
</style>