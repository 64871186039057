<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mx-auto">
          <v-avatar
              class="mx-auto d-block elevation-6"
              size="130"
            >
              <v-gravatar :email="currentUser.email" :size="130" />            
            </v-avatar>
          <v-card-text>
          

            <h6 class="overline mb-3">
              {{ role }}
            </h6>

            <h4 class="font-weight-light">
              {{ currentUser.display_name }}
            </h4>

            <p class="font-weight-light">
              {{ currentUser.email }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-text>
            <user-profile-form form-action="/" :user="currentUser"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {

    }
  },

  computed: {
    ...mapState("app", ["currentUser", "userRoles"]),
    role() {
      return this.userRoles.join(" / ")
    }
  }
}
</script>