<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    light
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-list>
      <v-list-item>

        <v-list-item-content>
          <v-img
            :src="logo"
            height="34"
            contain
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider/>

    <v-list
        nav
        dense
      >
        <v-list-item-group v-model="link" color="primary">
          <v-list-item
            v-for="(link, i) in linksToDisplay"
            :key="i"
            @click.stop="navigateTo(link.to)"
          >
            <v-list-item-icon>
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";
import logo from "../../images/logo-inverse.png";

export default {
  data: () => ({
    logo,
    links: [
      {
        to: "/",
        icon: "mdi-view-dashboard",
        text: "Dashboard",
        role: "admin"
      },
      {
        to: "/oauth/applications",
        icon: "mdi-openid",
        text: "OpenID Connect",
        role: "admin"
      },
      {
        to: "/admin/saml/service_providers",
        icon: "mdi-lan-connect",
        text: "SAML",
        role: "admin"
      },
      {
        to: "/user_profiles/show",
        icon: "mdi-account",
        text: "Meu Perfil"
      }
    ],
    linksToDisplay: [],
    responsive: false,
    link: null
  }),
  computed: {
    ...mapState("app", [
      "image",
      "color",
      "drawerIndex",
      "currentUser",
      "userRoles"
    ]),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    },
    items() {
      return this.$t("Layout.View.items");
    },
    sidebarOverlayGradiant() {
      return `${this.$store.state.app.sidebarBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`;
    }
  },
  beforeMount() {
    const links = [];

    this.links.forEach(link => {
      if (link.role === undefined || this.userRoles.indexOf(link.role) >= 0) {
        links.push(link);
      }
    });

    this.linksToDisplay = links;
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
    this.setPageState();
    this.link = this.drawerIndex;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations("app", [
      "setDrawer",
      "toggleDrawer",
      "setTitle",
      "setDrawerIndex"
    ]),
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    navigateTo(href) {
      if (window.location.pathname !== href) {
        window.location.href = href;
      }
    },

    setPageState() {
      const { pathname } = window.location;

      let index = -1;

      this.linksToDisplay.map((item, i) => {
        let regex = `${item.to.replace(/\//g, "\\/")}`;
        if (
          item.to === pathname ||
          pathname.search(new RegExp(regex, "gi")) !== -1
        ) {
          index = i;
        }
      });

      if (index >= 0) {
        const { text } = this.linksToDisplay[index];
        this.setTitle(text);
        this.setDrawerIndex(index);
      }
    }
  }
};
</script>

<style lang="sass">
#app-drawer
  .v-list__tile
    border-radius: 4px

    &--buy
      margin-top: auto
      margin-bottom: 17px

  .v-image__image--contain
    top: 9px
    height: 60%

  .search-input
    margin-bottom: 30px !important
    padding-left: 15px
    padding-right: 15px

  div.v-responsive.v-image > div.v-responsive__content
    overflow-y: auto
</style>