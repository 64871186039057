<template>
  <v-layout column fill-height>
    
    <v-data-table
      :headers="headers"
      :items="items"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("ServiceProvider.Service Providers") }}</v-toolbar-title>
          <v-spacer></v-spacer>       
          <v-btn :href="newItemUrl" color="primary" title="Novo">
            <v-icon>mdi-plus</v-icon>
            Novo
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-chip small label color="success" v-if="item.is_active">
          {{ $t("Common.active") }}
        </v-chip>

        <v-chip small label color="default" v-else>
          {{ $t("Common.deactive") }}
        </v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn icon small :href="`/admin/saml/service_providers/${item.id}/edit`">
          <v-icon small color="primary">
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn icon small :href="`/admin/saml/service_providers/${item.id}`">
          <v-icon small>
            mdi-eye
          </v-icon>
        </v-btn>

        <v-btn icon small @click="deleteItem(item)">
          <v-icon small color="error">
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <core-confirm ref="confirm"></core-confirm>
  </v-layout>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    newItemUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("Saml.ServiceProvider.Name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("Saml.ServiceProvider.Status"),
          align: "left",
          sortable: false,
          value: "is_active"
        },
        {
          text: "",
          align: "right",
          value: "action",
          sortable: false
        }
      ]
    };
  },

  methods: {
    deleteItem(service) {
      this.$refs.confirm
        .open(this.$t("Common.Delete"), this.$t("Common.AreYouSure?"), {
          color: "red",
          confirmText: this.$t("Common.Yes"),
          cancelText: this.$t("Common.Cancel")
        })
        .then(console.log);
    }
  }
};
</script>

