<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3">
        <material-card  color="orange"
          title="Usuários"
          :text="usersCount">
        </material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="10"
          hide-default-footer>
          
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Últimos usuários</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    users: {
      type: Array
    },
    usersCount: {
      type: String
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "display_name"
        },

        {
          text: "Email",
          value: "email"
        }
      ]
    };
  }
};
</script>

