<template>
  <v-card>
    <v-card-text>
      <v-form id="application-form" ref="form" 
        lazy-validation
        :value="valid" 
        :action="formAction"
        accept-charset="UTF-8"
        method="post">

        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="authenticity_token" v-model="csrf">
        <input type="hidden" name="_method" value="patch" v-if="application.id">

        <div class="pa-5">
          <template v-for="(field, i) in textFields">
            <v-text-field
              :name="`doorkeeper_application[${field.model}]`"
              :key="i"
              :value="form[field.model]"
              :prepend-icon="field.prependIcon"
              :label="field.label"
              :rules="field.rules"
              :required="field.required"
              validate-on-blur
            />
          </template>

          <v-text-field
            name="doorkeeper_application[scopes]"
            :value="scopes"
            prepend-icon="mdi-package-variant"
            label="Scopes"
          />

          <input type="hidden" name="doorkeeper_application[confidential]" value="0">
          <v-checkbox
            v-model="form.confidential"
            :value="form.confidential"
            :input-value="confidential"
            name="doorkeeper_application[confidential]" 
            label="Confidential" />
        </div>

        <div class="pa-5 text-right">
          <v-btn rounded outlined small color="primary" :href="backUrl">
            {{ $t("Common.Cancel") }}
          </v-btn>
          <v-btn color="primary" @click.stop="onSubmit" :disabled="!valid">
            {{ $t("Common.Save") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { rules } from "../../utils/rules";

export default {
  props: {
    application: Object,
    formAction: String,
    backUrl: String
  },
  data() {
    return {
      valid: true,
      form: {
        name: "",
        redirect_uri: "",
        confidential: true,
        scopes: []
      },
      csrf: "",
      textFields: []
    };
  },

  beforeMount() {
    this.textFields = [
      {
        model: "name",
        label: "Name",
        rules: [rules.required],
        prependIcon: "mdi-rename-box",
        required: true
      },

      {
        model: "redirect_uri",
        label: "Redirect URI",
        rules: [rules.required, rules.url],
        prependIcon: "mdi-link",
        required: true
      }
    ];

    const { name, redirect_uri, confidential, scopes } = this.application;

    this.form = {
      name,
      redirect_uri,
      confidential,
      scopes
    };
  },

  computed: {
    scopes() {
      return this.form.scopes.join(" ");
    },
    confidential() {
      return this.form.confidential ? 1 : 0;
    }
  },

  mounted() {
    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },

  methods: {
    onCancel() {
      window.location.href = this.backUrl;
    },

    onSubmit() {
      const { form } = this.$refs;
      if (form.validate()) {
        form.$el.submit();
      }
    }
  }
};
</script>