<template>
  <v-card>
    <v-card-text>
      <v-form id="service-provider-form" ref="form" 
        lazy-validation
        :value="valid" 
        :action="formAction"
        accept-charset="UTF-8"
        method="post">

        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="authenticity_token" v-model="csrf">
        <input type="hidden" name="_method" value="patch" v-if="service.id">

        <div class="pa-5">
          <template v-for="(field, i) in textFields">
            <h3 class="headline text--primary" v-if="field.header" :key="field.header">{{field.header}}</h3>
            <v-text-field
              :name="`service_provider[${field.model}]`"
              :key="i"
              :value="form[field.model]"
              :prepend-icon="field.prependIcon"
              :label="field.label"
              :rules="field.rules"
              :required="field.required"
              validate-on-blur
            />
          </template>
        </div>

        <div class="pa-5 text-right">
          <v-btn rounded outlined small color="primary" @click.stop="onCancel">
            {{ $t("Common.Cancel") }}
          </v-btn>
          <v-btn color="primary" @click.stop="onSubmit" :disabled="!valid">
            {{ $t("Common.Save") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { rules } from "../../utils/rules";

export default {
  props: {
    service: Object
  },
  data() {
    return {
      valid: true,
      form: {
        name: "",
        issuer_url: "",
        acs_url: "",
        login_url: "",
        post_logout_url: "",
        metadata_url: "",
        cert_fingerprint: ""
      },
      csrf: "",
      textFields: []
    };
  },

  beforeMount() {
    this.textFields = [
      {
        model: "name",
        label: "Name",
        rules: [rules.required],
        prependIcon: "mdi-rename-box",
        required: true,
        header: "Service Provider Info"
      },

      {
        model: "issuer_url",
        label: "Service Provider Issuer",
        rules: [rules.required],
        prependIcon: "mdi-link",
        required: true
      },

      {
        model: "acs_url",
        label: "Service Provider Assertion Consumer Service URL",
        rules: [rules.required, rules.url],
        prependIcon: "mdi-link",
        required: true
      },

      {
        model: "metadata_url",
        label: "Service Provider Metadata URL",
        rules: [rules.url],
        prependIcon: "mdi-link",
        required: true
      },

      {
        model: "login_url",
        label: "Service Provider Login URL",
        rules: [],
        prependIcon: "mdi-login",
        header: "Redirections"
      },

      {
        model: "post_logout_url",
        label: "Service Provider Post Logout URL",
        rules: [],
        prependIcon: "mdi-logout"
      },

      {
        model: "cert_fingerprint",
        label: "Service Provider Certificate Fingerprint",
        rules: [rules.required],
        prependIcon: "mdi-certificate",
        required: true,
        header: "Certificate"
      }
    ];
  },

  mounted() {
    const {
      name,
      issuer_url,
      acs_url,
      login_url,
      post_logout_url,
      metadata_url,
      cert_fingerprint
    } = this.service;

    this.form = {
      name,
      issuer_url,
      acs_url,
      login_url,
      post_logout_url,
      metadata_url,
      cert_fingerprint
    };

    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },
  computed: {
    formAction() {
      return this.service.id
        ? `/admin/saml/service_providers/${this.service.id}`
        : "/admin/saml/service_providers";
    }
  },
  methods: {
    onCancel() {
      window.location.href = "/admin/saml/service_providers";
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        document.querySelector("#service-provider-form").submit();
      }
    }
  }
};
</script>


