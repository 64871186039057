<template>
  <service-provider-form :service="service" />
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    service: Object
  },
  created() {
    this.setPageState();
  },
  methods: {
    ...mapMutations("app", ["setTitle", "setDrawerIndex"]),
    setPageState() {
      this.setTitle("SAML - Service Provider");
      this.setDrawerIndex(3);
    }
  }
};
</script>
