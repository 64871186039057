<template>
  <div class="text-center hidden-md-and-up">
    <a href="/">
      <img :src="image" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "/images/max-center-logo-dark.svg"
    }
  }
};
</script>

