<template>
  <v-container grid-list-md>
    <v-content>
      <div id="core-view">
        <slot></slot>
      </div>
      <!-- <core-footer /> -->
    </v-content>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Vuetify Material Dashboard by CreativeTim"
    };
  }
};
</script>

<style>
#core-view {
  padding: 50px 0;
}
</style>