<template>
  <v-layout column>
    <v-toolbar flat style="background-color: rgb(244, 243, 239);">
      <v-toolbar-title>{{ $t("Doorkeeper.Applications") }}</v-toolbar-title>
      <v-spacer/>
      <v-btn fab small :href="newItemUrl">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
    >

    <template v-slot:item.confidential="{ item }">
      {{ item.confidential ? $t("Common.Yes") : $t("Common.No")}}
    </template>
    
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

  </v-layout>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    newItemUrl: {
      type: String
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("Doorkeeper.Application.Name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("Doorkeeper.Application.CallbackURL"),
          align: "left",
          sortable: false,
          value: "redirect_uri"
        },
        {
          text: this.$t("Doorkeeper.Application.Confidential"),
          align: "left",
          sortable: false,
          value: "confidential"
        },
        {
          text: this.$t("Common.Actions"),
          align: "right",
          value: "action",
          sortable: false
        }
      ]
    };
  },
  methods: {
    editItem(application) {
      window.location.href = `/oauth/applications/${application.id}/edit`;
    }
  }
};
</script>