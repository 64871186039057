import { set, toggle } from '../../../utils/vuex';

export default {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  setTitle: set('title'),
  setDrawerIndex: set('drawerIndex'),
  setCurrentUser: set('currentUser'),
  setUserRoles: set('userRoles'),
};
