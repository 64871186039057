<template>
  <v-app-bar
    id="core-toolbar"
    app
    flat
    fixed
    style="background: #f4f3ef;"
    elevate-on-scroll
  >
    <div class="v-toolbar-title">
      <v-toolbar-title class="tertiary--text font-weight-light">
        <v-btn
          v-if="responsive"
          class="default v-btn--simple"
          dark
          icon
          @click.stop="onClickBtn"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
        {{ title }}
      </v-toolbar-title>
    </div>
    <v-spacer />
    <v-divider vertical class="mr-2"></v-divider>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn slot="activator" v-on="on" height="54" text>
          <strong class="user--name">{{ currentUser.display_name }}</strong>
          <v-avatar class="ml-2" size="32px">
            <v-gravatar :email="currentUser.email" :size="32" />
          </v-avatar>
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>

      </template>
      <v-list dense>
        <v-list-item href="/user_profiles/show">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("Common.Profile")  }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("Common.Logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {
  data: () => ({
    responsive: false,
    responsiveInput: false
  }),

  computed: {
    ...mapState("app", ["title", "currentUser"])
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    logout() {
      window.location.href = "/users/sign_out";
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    }
  }
};
</script>

<style>
#core-toolbar .v-toolbar__content {
  max-height: 75px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#core-toolbar a {
  text-decoration: none;
}
</style>
