<template>
    <v-content class="not-loggedin">
      <v-container fill-height align-center>
        <slot name="default"></slot>
      </v-container>
    </v-content>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import Vuetify from "vuetify/es5";

Vue.use(Vuetify, {
  theme: {
    primary: "#003DA5",
    secondary: "#FFFFFF",
    accent: "#DC1C2E",
    error: "#E53935",
    warning: "#ffeb3b",
    info: "#2196f3",
    success: "#4caf50"
  }
});

export default {};
</script>

<style lang="less">
@import "../../../assets/styleguide/colors.less";
@import "../../../assets/styleguide/typography.less";

.not-loggedin {
  background: linear-gradient(
          rgba(0, 0, 0, 0.50),
          rgba(0, 0, 0, 0.50)
        ), url('/images/not-logged-bg.jpg') !important;
  background-size: cover !important;

  .v-card {
    background-color: rgba(255, 255, 255, 0.85);
    .v-card__title {
      padding: 2rem 2.75rem;
    }
  }
}

.jumbo-app-title {
  font-weight: @bold;
  font-size: 71px;
}

.v-input.v-text-field {
  label {
    top: 0;
  }

  input {
    font-size: 14px;
    height: 28px;
  }
}

.v-input {
  padding: 0;

  label {
    font-size: 14px;
    line-height: 28px;
  }
}

.login--title {
  font-size: 1.5rem;
  font-weight: @bold;
  line-height: 35px;
  color: @accent-text;
}

.login--subhead {
  font-family: @secondary-font;
  font-size: 12px;
  line-height: 16px;
}

.v-icon {
  font-size: 21px;
}

.v-btn--large {
  padding: 0 60px;
}

@media screen and (max-width: 320px) {
  .application.not-loggedin {
    .v-card {
      .v-card__title {
        padding: 2rem 1.8rem;
      }
    }
  }
}
</style>


