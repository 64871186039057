/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true);
// const imagePath = (name) => images(name, true)

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

// Components
import '../components';
import Gravatar from 'vue-gravatar';
Vue.component('v-gravatar', Gravatar);

// Views
import '../views';

// Plugins
import vuetify from '../plugins/vuetify';
import '../plugins';

// Store
import store from '../store';

// i18n
import i18n from '../i18n';

// Application imports
import App from '../app.vue';

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    vuetify,
    store,
    i18n,
    el: '#app',
    components: {
      App,
    },
  });

  console.log(app);
});
