<template>
  <div class="pa-5">
    <authentication-logo class="mb-2"></authentication-logo>
    <h1 class="main__title">Login</h1>
    <div class="mt-3 mb-5 login--subhead">Digite seu email e senha para acessar</div>

    <v-form ref="form" 
      :action="action"
      method="post"
      v-model="valid"
      lazy-validation
    >
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" v-model="csrf">

      <v-text-field
        @keyup.enter="submit"
        v-model.trim="formData.emailAddress"
        type="email"
        :rules="emailRules"
        label="Email*"
        required
        prepend-icon="mdi-account-outline"
        :error="hasCloudError"
        name="user[email]"
      ></v-text-field>

      <v-text-field
        @keyup.enter="submit"
        v-model.trim="formData.password"
        type="password"
        :rules="passRules"
        label="Senha*"
        required
        prepend-icon="mdi-lock-reset"
        :error-messages="cloudError"
        name="user[password]"
      ></v-text-field>

      <v-checkbox
        color="primary"
        small
        label="Lembrar login"
        v-model="formData.rememberMe"
        name="user[remember_me]"
      ></v-checkbox>

      <div class="text-center">
        <v-btn
          class="submit-btn"
          color="primary"
          rounded
          large
          :loading="syncing"
          :disabled="!valid && !hasCloudError"
          @click="submit"
        >Entrar</v-btn>
      </div>
    </v-form>

    <div class="text-center mt-3">
      <a href="http://minharemax.com.br/password/forgot" class="caption grey--text forgot-password">Esqueceu sua senha?</a>
    </div>
    <br/>
    <div class="text-center">
      Ou
    </div>
    <br/>
    <div class="text-center">
      <v-btn
        class="white--text text-none"
        color="red"
        rounded
        large
        :loading="syncing_google"
        :disabled="!valid && !hasCloudError"
        @click="syncing_google=true"
        :href="'/authentication/google'"
      >Entrar com Google</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasCloudError: {
      type: Boolean
    },
    cloudError: {
      type: String
    },
    action: {
      type: String,
      required: true
    },
    alert: {
      type: String
    }
  },
  data() {
    return {
      passRules: [v => !!v || ""],

      emailRules: [
        v => !!v || "",
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || ""
      ],

      btnRounded: true,

      syncing: false,

      syncing_google: false,

      formData: {
        /* … */
      },

      formErrors: {
        /* … */
      },

      formRules: {
        emailAddress: { required: true, isEmail: true },
        password: { required: true }
      },

      valid: true,

      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content")
    };
  },
  methods: {
    submit: async function() {
      const { form } = this.$refs;
      if (form.validate()) {
        this.syncing = true;
        form.$el.submit();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 35px;
  color: #dc1c2e;
  margin-top: 0;
}

.v-application {
  .submit-btn.primary {
    width: 12em;
    background-color: #003da5 !important;
    border-color: #003da5 !important;
    text-transform: capitalize;
  }
}

.forgot-password {
  text-decoration: underline;
}
</style>
