export default {
  drawer: null,
  color: 'success',
  image:
    'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg',
  sidebarBackgroundColor: 'rgba(27, 27, 27, 0.74)',
  title: 'Dashboard',
  drawerIndex: 0,
  currentUser: {},
  userRoles: []
};
