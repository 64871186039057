<template>
  <v-app light>
    <template v-if="currentUser">
      <core-drawer />

      <core-toolbar />

      <core-view>
        <slot></slot>
      </core-view>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    currentUser: Object,
    roles: Array
  },

  beforeMount() {
    this.setCurrentUser(this.currentUser);
    const roleNames = this.roles.map(r => r.name);
    this.setUserRoles(roleNames);
  },

  methods: {
    ...mapMutations("app", ["setCurrentUser", "setUserRoles"])
  }
};
</script>
