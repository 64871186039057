<template>
  <v-form
    ref="form" 
    lazy-validation
    :value="valid" 
    :action="formAction"
    accept-charset="UTF-8"
    method="post">

    <input name="utf8" type="hidden" value="✓">
    <input type="hidden" name="authenticity_token" v-model="csrf">
    <input type="hidden" name="_method" value="patch">

    <div class="pa-5">
      <template v-for="(field, i) in textFields">
        <h3 class="headline text--primary" v-if="field.header" :key="field.header">{{field.header}}</h3>
        <v-text-field
          :name="`user[${field.model}]`"
          :key="i"
          :value="form[field.model]"
          :prepend-icon="field.prependIcon"
          :label="field.label"
          :rules="field.rules"
          :required="field.required"
          validate-on-blur
        />
      </template>
    </div>
  </v-form>
</template>

<script>
import { rules } from "../../utils/rules";

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    formAction: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      form: {
        first_name: "",
        last_name: "",
        display_name: "",
        password: "",
        password_confirmation: ""
      },
      csrf: "",
      textFields: []
    };
  },

  beforeMount() {
    this.textFields = [
      {
        model: "first_name",
        label: "Nome",
        rules: [rules.required],
        prependIcon: "mdi-rename-box",
        required: true
      },

      {
        model: "last_name",
        label: "Sobrenome",
        rules: [rules.required],
        prependIcon: "mdi-rename-box",
        required: true
      },

      {
        model: "display_name",
        label: "Exibir como",
        rules: [rules.required],
        prependIcon: "mdi-account",
        required: true
      },
      {
        model: "email",
        label: "Email",
        rules: [rules.required],
        prependIcon: "mdi-email",
        required: true
      }
    ];
  },

  mounted() {
    const { first_name, last_name, display_name, email } = this.user;

    this.form = {
      first_name,
      last_name,
      display_name,
      email
    };

    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },
  methods: {
    onCancel() {
      window.location.href = "/admin/saml/service_providers";
    },

    onSubmit() {
      const { form } = this.$refs;
      if (form.validate()) {
        form.$el.submit();
      }
    }
  }
};
</script>
